/* stylelint-disable plugin/selector-bem-pattern */
@use '~@moda/om';

.GridPumoModule {
  @include om.stack(6);

  & {
    padding: om.spacing(5, 3);
  }

  &__header {
    text-align: center;
  }

  &__subheader {
    text-align: center;
  }

  @include om.breakpoint(md, $prop: max-width) {
    &__grid {
      @include om.stack(3);
    }

    &__column {
      @include om.stack(3, $direction: horizontal);
    }
  }

  @include om.breakpoint(md) {
    &__header {
      @include om.text('h2');

      text-align: center;
    }

    &__subheader {
      @include om.text('h5');

      text-align: center;
    }

    &__grid {
      @include om.stack(5, $direction: horizontal);

      > * {
        flex: 1;
      }
    }

    &__column {
      @include om.stack(5);
    }
  }
}
