@use '~@moda/om';

.VariantCellProductPrice {
  @include om.text(body1);

  align-items: center;
  justify-content: center;
  color: om.color(ink);
  user-select: none;

  &__wrapper {
    padding: om.spacing(2, 0);

    // Separate each element with a normal space
    > * {
      // stylelint-disable-next-line plugin/selector-bem-pattern, max-nesting-depth
      &::before {
        content: ' ';
      }
    }
  }

  &__original {
    color: om.color('cement');
    text-decoration: line-through;
  }

  &__current {
    color: om.color(ink);
  }

  &__deposit {
    color: om.color('cement');
  }

  &__markdown-percent {
    color: om.color(code-red);
    font-weight: bold;
  }
}
