@use '~@moda/om';

.CarouselPumoModuleCategories {
  &__mobile {
    text-align: center;
  }

  &__list {
    @include om.stack(2, $direction: horizontal);

    & {
      display: inline-flex;
      max-width: 100%;
      overflow-x: auto;
      padding: om.spacing(0, 6, 3);
    }

    @include om.breakpoint(sm, $prop: max-width) {
      padding: om.spacing(0, 4, 3);
    }

    @include om.breakpoint(xs, $prop: max-width) {
      padding: om.spacing(0, 2, 3);
    }
  }

  &__button {
    flex-shrink: 0;
  }
}
