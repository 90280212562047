@use '~@moda/om';

// TODO MOJO
// * flexbox this,
// * standardize the magic numbers here

.TimeSection {
  display: inline-block;
  padding: 0 20px;
  position: relative;

  &__time {
    font-size: 0.875rem;
  }

  &__number {
    font-size: om.dangerously-get-font-size(5);
    width: om.space(7);
  }

  &:not(:first-child) {
    &::before {
      content: ':';
      font-size: 1.125rem;
      position: absolute;
      left: -0.125rem;
      top: 0.75rem;
    }
  }

  @include om.breakpoint(md, max-width) {
    padding: 0 0.6rem;
  }
}
