@use '~@moda/om';

.PumoModuleImage {
  $self: &;

  position: relative;
  width: 100%;
  background-color: om.color(noise);

  &--cover {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
  }

  &--responsive {
    height: 0;
  }
}
