@use '~@moda/om';

.TrunkshowCell {
  $self: &;

  display: block;
  position: relative;
  text-decoration: none;
  color: om.color(ink);

  &__container {
    color: om.color(ink);
    text-decoration: none;
    text-align: center;
  }

  &__overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: om.spacing(4, 2);
    background-color: om.color(ink, 0.75);
    color: om.color('snow');
    text-decoration: none;
    text-align: center;
  }

  &__timestamp {
    @include om.text(eyebrow);
  }

  &__title {
    @include om.text(h5, $font: serif);
  }

  &:hover {
    z-index: 1;
    box-shadow: 0 om.space(2) om.space(2) 0 om.color(ink, 0.1);
  }

  &__details {
    @include om.text(body2);

    margin: om.space(1) om.space(0);
    text-align: center;
    text-transform: uppercase;
  }
}
