@use '~@moda/om';

.CarouselPumoModule {
  @include om.breakpoint(md) {
    @include om.stack(5, $direction: horizontal);

    &__editorial {
      width: 33%;
    }

    &__content {
      width: 100%;
    }

    &--editorial &__content {
      width: calc(67% - #{om.space(5)});
    }
  }

  @include om.breakpoint(md, $prop: max-width) {
    @include om.stack(3);
  }
}
