@use '~@moda/om';

.HomePageHeaderList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: om.space(1);

  &__header {
    margin-bottom: om.space(2);
  }
}
