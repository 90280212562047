@use '~@moda/om';

/* stylelint-disable */

.PumoModuleSlide {
  position: relative;
  overflow: hidden;
  height: 100%;

  > a {
    display: block;
    text-decoration: none;
    height: 100%;
  }

  img,
  video {
    display: block;
    height: auto;
    width: 100%;
  }

  &__editorial {
    height: 100%;
    width: 100%;
    display: flex;
  }

  &__editorial--withImage {
    position: absolute;
    top: 0;
    left: 0;
  }

  @include om.breakpoint(md, $prop: max-width) {
    &__editorial--withImage {
      position: unset;
      top: 0;
      left: 0;
    }
  }
}
