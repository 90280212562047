@use '../../styles/z-indexes';
@use '~@moda/om';

.EditorialVideo {
  position: relative;
  overflow: hidden;

  &__loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: z-indexes.z(one);
  }

  &__button {
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    bottom: om.space(5);
    z-index: z-indexes.z(one);
  }

  &__play-pause-button {
    left: om.space(4);
  }

  &__item--hidden {
    display: none;
  }

  &__sound-button {
    right: om.space(4);
  }
}
