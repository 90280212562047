@use '~@moda/om';

.SinglePumoModule {
  width: 100%;

  &__wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  &__nav-buttons {
    color: om.color('snow');
  }
}
