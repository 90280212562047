@use '~@moda/om';

.RecommendationsCarousel {
  @include om.stack(3);

  &__description {
    text-align: center;
  }

  &__add-to-bag {
    align-self: center;
  }
}
