@use '~@moda/om';

.SmsPumoModule {
  background-color: om.color(klein-blue);
  width: 100%;

  @include om.breakpoint(md) {
    display: flex;
  }

  &__slides {
    @include om.breakpoint(md) {
      flex-basis: 50%;
    }
  }

  &__title,
  &__body-text {
    text-align: center;
  }

  &__discount {
    font-weight: bold;
  }

  &__form {
    display: flex;
    justify-content: center;
  }

  &__input {
    min-width: 20rem;
  }

  &__form-content {
    max-width: 20rem;
  }

  &__content {
    padding: om.space(6) om.space(8);

    @include om.breakpoint(md) {
      flex-basis: 50%;
    }

    @include om.breakpoint(lg) {
      padding: om.space(10) om.space(8);
    }
  }

  &__email-legal {
    align-self: flex-start;
  }

  &__call-to-action {
    min-width: 20rem;
  }

  &__call-to-action,
  &__loading {
    align-self: center;
  }

  // stylelint-disable-next-line plugin/selector-bem-pattern
  .Field__label {
    color: om.color(cream);
  }
}
