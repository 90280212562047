@use '~@moda/om';
@use '../../styles/mixins';

.PumoModuleEditorialText {
  color: om.color(ink);

  &__text {
    text-align: center;
    white-space: normal;

    &--space-between {
      @include om.stack(4);
    }
  }

  &__cta {
    @include om.text(eyebrow);

    & {
      margin-top: om.space(4);
      display: inline-block;
    }
  }

  &__cta-button {
    margin-top: om.space(4);
    text-transform: uppercase;
  }

  &__cta-underline {
    @include mixins.thin-underline;
  }
}
