@use '~@moda/om';

.Carousel {
  $transition-speed: 750ms;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: om.space(9);
  overflow: hidden;

  &__rail {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    transition: transform $transition-speed;
    will-change: transform;
  }

  &__viewer {
    width: 100%;
    overflow: hidden;
  }

  &__cell {
    width: 100%;
    justify-content: space-evenly;
    text-align: center;
  }

  &__dots {
    margin: om.spacing(2, 0);
  }
}
