@use '~@moda/om';

.ModuleTrunkshowCountdown {
  color: om.color('ink');

  &__intro {
    @include om.text(eyebrow);
  }

  &__clock {
    margin-bottom: om.space(5);
  }
}
