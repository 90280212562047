@use '~@moda/om';

.HomePageHeaderListItem {
  display: flex;
  border-top: 1px om.color('elephant') solid;
  padding-top: om.space(2);
  padding-bottom: om.space(2);

  @include om.breakpoint('lg') {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__image,
  &__text {
    flex: 1;
  }

  &__image {
    width: 100%;
    padding-bottom: 50%;
    background-color: om.color(noise);
  }

  &__text {
    @include om.stack(2);

    & {
      justify-content: center;
      padding: om.space(3);
    }
  }

  &__headline {
    @include om.text(h6, 'serif');
  }

  @include om.breakpoint(xs, $prop: min-width) {
    &__text {
      @include om.stack(4);

      & {
        justify-content: center;
        padding: om.spacing(4);
      }
    }

    &__headline {
      @include om.text(h4, 'serif');
    }
  }

  @include om.breakpoint(sm, $prop: min-width) {
    &__text {
      @include om.stack(5);

      & {
        justify-content: center;
        padding: om.spacing(5);
      }
    }

    &__headline {
      @include om.text(h3, 'serif');
    }
  }
}
