@use '~@moda/om';

.CarouselArrow {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  &:focus {
    outline: 0;
  }

  &--left {
    left: 0;
    padding: om.spacing(6, 6, 6, 3);
  }

  &--right {
    right: 0;
    padding: om.spacing(6, 3, 6, 6);
  }

  // Hide, but prevent click-through
  &:disabled {
    cursor: initial;
    opacity: 0;
  }

  &__icon {
    // stylelint-disable-next-line plugin/selector-bem-pattern
    > svg {
      filter: drop-shadow(1px 0 0 om.color('snow', 0.5)) drop-shadow(-1px 0 0 om.color('snow', 0.5));
    }
  }
}
