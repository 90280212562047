@use '~@moda/om';

.EmailPumoModule {
  display: flex;

  &__image,
  &__email-unit {
    flex: 1;
  }

  &__email-unit {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    width: 75%;
  }

  @include om.breakpoint(md, $prop: max-width) {
    flex-direction: column;

    &__form {
      width: 100%;
    }
  }
}
