@use '~@moda/om';

.AddToBagButton {
  padding: om.spacing(2, 7);

  // stylelint-disable-next-line plugin/selector-bem-pattern
  > * {
    margin: 0;
  }

  &__text {
    &:only-child {
      padding: om.spacing(2, 0);
    }
  }
}
