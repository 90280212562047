@use '~@moda/om';

.PumoModuleEditorialTextElement {
  &--eyebrow {
    @include om.text(eyebrow);
  }

  &--headline {
    @include om.text(h3, serif);

    text-transform: uppercase;
  }

  &--tagline {
    @include om.text(body1);
  }
}
