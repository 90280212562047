@use '~@moda/om';
@use '../../styles/mixins';

// HACK:
// Pumo regrettably implements a WYSIWYG editor which emits inline styles.
// This set of overrides allows us to target the erroneous styling and style them to our liking.
// Future iterations of this editor should avoid this kind of styling.

/* stylelint-disable */
.NormalizePumoStyles {
  span[style*='font-family: austin-pumo'],
  span[style*='font-family: miller-headline-roman'],
  span[style*='font-family: miller-headline'],
  span[style*='font-family: miller-headline-light'],
  span[style*='font-family: "Caslon"'],
  span[style*='font-family:"Moda Operandi Serif"'] {
    font-family: om.font-family('serif') !important;
  }

  span[style*='font-family: "Gotham Medium"'],
  span[style*='font-family: Gotham Medium'],
  span[style*='font-family: Gotham-Medium'],
  span[style*='font-family: Moda-Gotham-Medium'],
  span[style*='font-family: "Gotham Book"'],
  span[style*='font-family: Gotham-Book'],
  span[style*='font-family: Moda-Gotham-Book'],
  span[style*='font-family: "Moda Operandi Sans"'] {
    font-family: om.font-family('sans') !important;
  }

  $pumo-treatments: (
    '12px': eyebrow,
    '13px': body2,
    '14px': body1,
    '16px': h6,
    '20px': h5,
    '24px': h4,
    '28px': h4,
    '32px': h3,
    '38px': h3,
    '42px': h3,
    '64px': h2
  );

  $important: (font-size, line-height, letter-spacing, text-transform);

  @each $size, $treatment in $pumo-treatments {
    span[style*='font-size: #{$size}'],
    span[style*='font-size:#{$size}'] {
      @include om.text($treatment, $important: $important);
    }
  }

  u {
    @include mixins.thin-underline();
  }

  a {
    text-decoration: none;
    color: unset;
  }
}
/* stylelint-enable */
