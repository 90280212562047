@use '~@moda/om';
@use '../../styles/mixins';

.EmailPumoModuleForm {
  @include om.stack(6);

  & {
    padding: om.space(4);
  }

  &__text {
    @include om.stack(4);

    & {
      align-items: center;
      text-align: center;
    }
  }

  &__eyebrow {
    @include om.text(eyebrow);
  }

  &__headline {
    @include om.text(h3, serif);
  }

  &__divider {
    width: om.space(6);
    height: om.space(1);
    border: none;
    background-color: om.color(ink);
  }

  &__tagline {
    @include om.text(body1);
    @include om.stack(1);
  }

  &__cta {
    @include om.text(eyebrow);
    @include mixins.thin-underline;
  }

  &__form {
    @include om.stack(4);

    & {
      align-items: center;
    }
  }

  &__options {
    @include om.stack(4, $direction: horizontal);
  }

  &__already-subscribed {
    @include om.text(body1);

    color: om.color(burnt-orange);

    // stylelint-disable-next-line plugin/selector-bem-pattern
    > a {
      text-decoration: underline;
    }
  }

  &__opt-out {
    @include om.text(body2);

    text-align: center;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    &,
    > a {
      color: om.color(cement);
    }

    // stylelint-disable-next-line plugin/selector-bem-pattern
    > a {
      text-decoration: underline;
    }
  }

  &__email {
    width: 100%;
  }
}
