@use '~@moda/om';

.PaginatedSlider {
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;

  &__pages {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
  }

  &__rail {
    display: flex;
    transition: transform 250ms;
  }

  &__page {
    display: flex;
    width: 100%;
    flex-shrink: 0;
  }

  &__cell {
    flex: 1;
  }

  &__next {
    padding: om.spacing(0, 0, 0, 2);
  }

  &__prev {
    padding: om.spacing(0, 2, 0, 0);
  }

  &__next,
  &__prev {
    align-items: stretch;
    color: om.color(cement);
    cursor: pointer;

    &:focus {
      outline: 0;
      color: om.color(ink);
    }

    &:disabled {
      color: om.color(elephant);
      cursor: default;
    }
  }
}
