@use '~@moda/om';

.HomePageHeaderPumoModule {
  @include om.breakpoint(lg, $prop: min-width) {
    display: flex;
    width: 100%;

    &__carousel {
      flex: 1;
      flex-shrink: 0;
      padding: om.spacing(2, 2, 0, 0);
      border-top: 1px solid om.color(elephant);
      flex-basis: 67.5%;
    }

    &__list {
      flex: 1;
      flex-basis: 32.5%;
    }
  }
}
