// stylelint-disable plugin/selector-bem-pattern
@use '~@moda/om';
@use 'variables';

@mixin moda-swiper-carousel() {
  $self: &;

  overflow: hidden;
  padding-bottom: om.space(5);
  width: 100%;

  &--multiple-slides-per-view {
    padding: om.spacing(0, 1, 5);
  }

  &:not(#{$self}--initialized) {
    .swiper-slide-duplicate {
      display: none;
    }
  }

  &__slide {
    &:empty {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    padding-left: om.space(8);
    padding-right: om.space(8);
    width: 100%;
  }

  &__slides {
    z-index: auto;
  }

  &__next {
    right: 0;
  }

  &__prev {
    left: 0;
  }

  &__next,
  &__prev {
    align-items: center;
    color: om.color('ink');
    cursor: pointer;
    display: flex;
    height: 100%;
    margin: 0;
    mix-blend-mode: difference;
    justify-content: center;
    position: absolute;
    top: 0;
    transition: opacity 250ms;
    user-select: none;
    width: om.space(8);
    z-index: variables.z(one);

    &:hover {
      opacity: 0.75;
    }

    &:focus {
      outline: 0;
    }
  }

  &__navigation--disabled {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
  }

  &__pagination--bullets {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;

    @include om.breakpoint(sm, $prop: 'max-width') {
      pointer-events: none;
    }
  }

  &__bullet {
    display: inline-block;
    height: om.space(2);
    width: om.space(2);
    border-radius: 100%;
    background: om.color(ink);
    opacity: 0.2;
    margin: om.spacing(0, 2);
    cursor: pointer;

    &--active {
      opacity: 1;
    }
  }

  @include om.breakpoint(md, $prop: max-width) {
    &__wrapper {
      margin: 0 auto;
      padding: om.spacing(0, 0, 5, 0);
    }

    &__next,
    &__prev {
      display: none;
    }
  }
}
// stylelint-enable plugin/selector-bem-pattern
