@use '~@moda/om';
@use '../../../styles/variables';
@use '../../../styles/moda_swiper_carousel';

.ProductSwiperCarousel {
  @include moda_swiper_carousel.moda-swiper-carousel;

  & {
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__next {
    &--hidden {
      visibility: hidden;
    }
  }

  &__prev {
    &--hidden {
      visibility: hidden;
    }
  }

  &__chevron {
    top: 25%;
  }

  &__next,
  &__prev {
    align-items: normal;
    mix-blend-mode: normal;
  }

  &__navigation {
    &--locked {
      visibility: hidden;
    }
  }

  @include om.breakpoint(md, $prop: max-width) {
    &__next,
    &__prev {
      display: block;
      bottom: om.space(4);
      height: 40px;
      top: auto;
      left: auto;
    }

    &__prev {
      right: om.space(7);
    }

    &__next {
      right: 0;
    }
  }
}
