@use '~@moda/om';

.VariantCell {
  $self: &;

  position: relative;
  text-align: center;

  // Top half
  &__content {
    position: relative;
  }

  &__favorite {
    right: om.space(2);
    top: om.space(2);
  }

  &__image {
    display: flex;
    justify-content: center;
  }

  &__actions {
    position: absolute;
    bottom: om.space(3);
    left: om.space(3);
    right: om.space(3);
    background-color: om.color('snow');
  }

  // Bottom half
  &__details {
    color: om.color(ink);
    padding: om.spacing(2, 4);
  }

  &__admin-product-info {
    padding-bottom: 0;
  }

  &--active {
    z-index: 1;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    > #{$self}__wrapper {
      box-shadow: 0 om.space(2) om.space(2) 0 om.color(ink, 0.1);
    }
  }

  &--admin-mode {
    // stylelint-disable-next-line plugin/selector-bem-pattern
    #{$self}__wrapper {
      border: om.space(1) solid;
    }
  }
}
