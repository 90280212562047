@use '~@moda/om';

.CarouselDot {
  $self: &;
  $carousel-dot-size: om.space(2);

  display: block;
  font-size: 100%;
  padding: om.space(1);
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &__dot {
    position: relative;
    padding: om.space(2);
    border-radius: 50%;
    border: 1px solid transparent;

    &::before {
      display: block;
      content: '';
      width: $carousel-dot-size;
      height: $carousel-dot-size;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: om.color(elephant);
    }
  }

  &--active {
    // stylelint-disable-next-line plugin/selector-bem-pattern
    #{$self}__dot {
      pointer-events: none;
      border-color: om.color(ink);

      // stylelint-disable-next-line plugin/selector-bem-pattern, max-nesting-depth
      &::before {
        background-color: om.color(ink);
      }
    }
  }
}
