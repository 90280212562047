@use '~@moda/om';
@use '../../styles/mixins';

.CallToAction {
  display: inline-block;

  // Wrap in a span because sometimes surrounding context is flex
  // which will cause a wide underline spanning the context
  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  > span {
    @include om.text(eyebrow);
    @include mixins.thin-underline;

    /* stylelint-disable-next-line plugin/selector-bem-pattern */
    & {
      display: inline-block;
      color: om.color('ink');
    }
  }
}
