// stylelint-disable plugin/selector-bem-pattern
@mixin thin-underline() {
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid;
    opacity: 0.75;
  }
}
// stylelint-enable plugin/selector-bem-pattern
