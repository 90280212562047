@use '~@moda/om';

.Pill {
  @include om.text(body2);

  flex-shrink: 0;
  display: inline-block;
  padding: om.space(2) om.space(4);
  border: 1px solid om.color('elephant');
  color: om.color('ink');
  text-transform: none;
  text-decoration: none;

  &:hover {
    color: om.color('ink');
    background-color: om.color('noise');
    border: 1px solid om.color('elephant');
  }

  &:disabled {
    color: om.color('elephant');
  }
}
