@use '~@moda/om';

/* stylelint-disable */
.MultiPumoModule {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // TODO: Fix class
  > .PumoModuleSlide {
    width: 50%;
    padding: 0 om.space(3);

    @include om.breakpoint(md, $prop: max-width) {
      width: 100%;
      padding: 0;
    }
  }

  // TODO: Fix this please
  &--3 {
    display: flex;
    justify-content: center;

    > .PumoModuleSlide {
      width: calc((1 / 3) * 100%);
      padding: 0 om.space(3);

      @include om.breakpoint(md, $prop: max-width) {
        width: 100%;
        padding: 0;
      }
    }
  }
}
