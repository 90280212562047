@use '~@moda/om';

.VideoPumoModule {
  @include om.breakpoint(md) {
    @include om.stack(0, $direction: horizontal);

    & {
      align-items: center;
    }

    // stylelint-disable-next-line plugin/selector-bem-pattern
    > * {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}
