@use '~@moda/om';

$add-to-bag-height: 3.25rem;
$availability-height: 1.25rem;
$badge-height: 2.5rem;

.VariantCellDetails {
  $self: &;

  color: om.color(ink);
  padding: om.spacing(2);

  &--resting {
    padding-bottom: #{om.space(2) + $add-to-bag-height};

    // stylelint-disable-next-line plugin/selector-bem-pattern
    &#{$self}--without-availability {
      padding-bottom: #{om.space(2) + $add-to-bag-height - $availability-height};
    }

    // stylelint-disable-next-line plugin/selector-bem-pattern
    &#{$self}--with-tag {
      padding-bottom: #{om.space(2) + $add-to-bag-height - $badge-height};
    }

    // stylelint-disable-next-line plugin/selector-bem-pattern
    &#{$self}--without-availability#{$self}--with-tag {
      padding-bottom: #{om.space(2) + $add-to-bag-height - $badge-height - $availability-height};
    }
  }

  &__add-to-bag {
    height: $add-to-bag-height;
  }

  &__anchor {
    color: om.color(ink);
    text-decoration: none;
  }

  &__availability {
    @include om.text(body1);

    height: $availability-height;
    color: om.color(brick);
    text-transform: capitalize;

    &--hidden {
      visibility: hidden;
    }
  }

  &__designer-name {
    @include om.text(eyebrow);

    font-weight: 700;
  }

  &__product-name {
    @include om.text(body1);
  }

  &__more-colors {
    @include om.text(body1);

    display: block;
    height: om.space(5);
    width: 100%;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
      outline: 0;
    }
  }

  &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $badge-height;
  }
}
