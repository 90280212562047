@use '~@moda/om';

.VariantCellSizeSliderButton {
  @include om.text(body1);

  width: 100%;
  padding: om.spacing(2, 0);
  color: om.color(ink);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
    text-decoration: underline;
  }

  &:disabled {
    color: om.color(elephant);
    pointer-events: none;
    cursor: default;
  }
}
