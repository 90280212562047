@use '~@moda/om';

.PillsPumoModule {
  @include om.stack(3, horizontal);

  & {
    width: 100%;
    padding: om.space(4) 0;
    justify-content: center;
  }

  @include om.breakpoint(md, $prop: max-width) {
    padding: om.space(3);
    overflow: auto;
    justify-content: flex-start;
  }
}
