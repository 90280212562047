@use '~@moda/om';

.Grid {
  $grid-cell-mobile-min-size: 9rem;
  $grid-cell-desktop-min-size: 13rem;
  $grid-cell-max-size: 20rem;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($grid-cell-desktop-min-size, 1fr));
  grid-gap: om.space(6);

  @include om.breakpoint(sm, $prop: max-width) {
    grid-template-columns: repeat(auto-fill, minmax($grid-cell-mobile-min-size, 1fr));
    grid-gap: om.space(2);
  }

  &--loading {
    $max-cell-height: 540px;
    $safe-transparent: om.color('snow', 0.1%);

    position: relative;
    height: $max-cell-height;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: om.space(11);
      background: linear-gradient($safe-transparent 0%, white 100%);
    }
  }

  // stylelint-disable-next-line plugin/selector-bem-pattern
  > * {
    max-width: $grid-cell-max-size;
  }
}
