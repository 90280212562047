@use '~@moda/om';

.ColorSelector {
  &--flex {
    @include om.stack(1, $direction: horizontal);

    & {
      align-items: center;
    }
  }

  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(om.space(6), 1fr));
    grid-gap: om.space(1);
  }

  &__swatch {
    flex-shrink: 0;

    // TODO: For some reason this gets clobbered inside of slider carousels
    box-sizing: border-box;
  }

  &__more {
    line-height: normal;
  }
}
